<template>
	<div class="WeCom">
		<div class="WeCom-top-nav" v-if="$route.meta.header">
			<div class="top-nav-item" :class="route==item.url?'active':''" v-for="(item,index) in navList" :key="item.id" @click="navItem(item,index)">
				<i class="iconfont" v-html="item.icon"></i>
				{{item.name}}
			</div>
		</div>
		<div class="WeCom-wrapper" :class="{'WeCom-wrapper2':!$route.meta.header,'WeCom-wrapper-xiadan':$route.meta.name=='下单'}">
			<keep-alive>
				<router-view />
			</keep-alive>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				route: null,
				// activeIndex: '',
				navList: [{
					id: 1,
					name: '客户',
					icon:'&#xe632;',
					url:'/qywx/custom'
				},{
					id: 2,
					name: '下单',
					icon:'&#xe6cf;',
					url:'/qywx/placeOrder'
				},{
					id: 3,
					name: '查件',
					icon:'&#xe61e;',
					url:'/qywx/checkPiece'
				},{
					id: 4,
					name: '订单',
					icon:'&#xe63f;',
					url:'/qywx/orderGoods'
				}]
			};
		},
		watch: {
			$route() {
				this.route = this.$route.path;
                this.checkUserInfo()
			},
		},
		created() {
			this.route = this.$route.path;
		},
		mounted(){
			this.setRem()
			window.onresize = () => {
			  this.setRem()
			}
		},
		methods: {
            checkUserInfo() {
                let userId = localStorage.getItem('userId')
                if(!userId && this.route != '/qywx'){
                    this.$router.push('/qywx')
                }

                let customerUserId = localStorage.getItem('customerUserId')
                console.log(customerUserId)
                if(!customerUserId && this.route == '/qywx/orderGoods'){
                    this.$router.push('/qywx/custom')
                }
            },
			navItem(item,index) {
				// this.activeIndex = index
				this.$router.push(item.url)
			},
			setRem() {
			  var html = document.querySelector('html');
			  html.style.fontSize = html.offsetWidth / 7.5 + "px";
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import "@/assets/css/wecom.scss";
</style>
